<template>
    <div class="content">
        <div class="row">

            <div class="col-md-12">
                <vuestic-widget :headerText="$t('view.event.title')">
                    <p>{{$t('view.event.description')}}</p>
                    <div class="row">
                        <div class="col-md-12 pull-right" v-if=" VerifyTeam()">
                            <button type="button" v-on:click="newEvent" class="btn btn-primary btn-sm">{{$t('view.event.new')}}</button>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <vuestic-tabs class="tabs" :names="['Creados', 'Lanzados','Cerrados']">
                    <div slot="Creados">
                        <TableEventCreated></TableEventCreated>
                    </div>
                    <div slot="Lanzados">
                        <TableEventLaunched></TableEventLaunched>
                    </div>
                    <div slot="Cerrados">
                        <TableEventClosed></TableEventClosed>
                    </div>
                </vuestic-tabs>

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import TableEventCreated from '../tables/TableEvent/TableEventCreated.vue'
import TableEventLaunched from '../tables/TableEvent/TableEventLaunched.vue'
import TableEventClosed from '../tables/TableEvent/TableEventClosed.vue'
import { SpringSpinner } from 'epic-spinners'

import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name: 'Table',
  components: {
    SpringSpinner,
    TableEventCreated,
    TableEventLaunched,
    TableEventClosed
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
    }),
    moreParams () {
      // HACK
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.appendParams[this.filterQuery] = this.filterText
      return this.appendParams
    },
    dataModeFilterableFieldsComputed () {
      const dataItem = this.tableData.data[0] || {}
      const filterableFields = this.dataModeFilterableFields

      if (!filterableFields.length) {
        const itemFields = Object.keys(dataItem)
        itemFields.forEach(field => {
          if (typeof dataItem[field] !== 'object') {
            filterableFields.push(field)
          }
        })
      }

      return filterableFields
    },
    filteredTableData () {
      const txt = new RegExp(this.filterText, 'i')

      let filteredData = this.tableData.data.slice()

      filteredData = this.tableData.data.filter((item) => {
        return this.dataModeFilterableFieldsComputed.some(field => {
          const val = item[field] + ''
          return val.search(txt) >= 0
        })
      })

      return {
        data: filteredData
      }
    },
    paginationPathComputed () {
      return this.apiMode ? this.paginationPath : 'pagination'
    }
  },
  methods: {
    newEvent () {
      this.$router.push('event/new')
    },
    VerifyTeam () {
      if (typeof this.auth.me.company_team !== 'undefined') {
        if (this.auth.me.company_team.id_rol_team != 3) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss">

    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }
</style>
